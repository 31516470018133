import {RootState} from "@app/store";
import {RemoteData, RemoteDataStatus} from "../../../libs/remote";
import {ConfirmationLoginResponseDTO, ResetConfirmationCodeResponseDTO, UserResponseDTO} from "@app/api/generated-api";
import {NoticeProps} from "@app/store/core/types";


export const selectSignIn = (state: RootState): RemoteData<ConfirmationLoginResponseDTO> => state.core.auth;

export const selectConfirmStatus = (state: RootState): RemoteDataStatus => state.core.confirmStatus;

export const selectResetConfirmStatus = (state: RootState): RemoteData<ResetConfirmationCodeResponseDTO> => state.core.resetConfirmStatus;

export const selectUser = (state: RootState): RemoteData<UserResponseDTO> => state.core.user;

export const selectPostRegistrationFormStatus = (state: RootState): RemoteData<string> => state.core.postRegistrationFormStatus;

export const selectPolicyVisible = (state: RootState): boolean => state.core.isPolicyVisible;

export const selectConfirmEmail = (state: RootState): string => state.core.isConfirmEmailModal;

export const selectNoticeState = (state: RootState): NoticeProps => state.core.notices;
