import styled, { css } from "styled-components";

export const Layout = styled.section`
  ${({ theme: { unit } }) => css`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    z-index: 21; //выше модалки
  `}
`;

export const Section = styled.section`
  ${({ theme: { unit } }) => css`
    padding: 40px 15px;
    max-width: 1390px;
    margin: 0 auto;
    font-size: 14px;
  `}
`;

export const Title = styled.h1`
  ${({ theme: { breakpoints } }) => css`
    font-size: 24px;
    margin: 0;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      word-break: break-word;
    }
  `}
`;

export const Subtitle = styled.h2`
  ${({ theme: { breakpoints } }) => css`
    font-size: 20px;
    margin-bottom: 16px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      font-size: 24px;
      word-break: break-word;
    }
  `}
`;

export const DateUpdate = styled.h4`
  ${({ theme: { breakpoints } }) => css`
    margin-bottom: 16px;
    font-size: 18px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      font-size: 20px;
    }
  `}
`;

export const Text = styled.p`
  margin-bottom: 24px;
  line-height: 1.3;
`;

export const List = styled.ul`
  ${({ theme: { unit } }) => css`
    font-size: 14px;
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 15px;
    margin-bottom: 24px;

    li {
      line-height: 1.3;
    }
  `}
`;

export const PolicyContainer = styled.section`
  ${({ theme: { breakpoints } }) => css`
    margin-top: 29px;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      margin-top: 22px;
    }
  `}
`;

export const Wrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    max-width: 1390px;
    margin: 0 auto;
    padding: 0 15px;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      padding: 0 40px;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      padding: 0 15px;
      max-width: 100%;
    }
  `}
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 32px;
`;

export const CloseButton = styled.button`
  cursor: pointer;
`;
