import colors from "./colors";
import breakpoints from "./breakpoints";
import indents from "./indents";
import typography from "./typography";

export const theme = {
    colors,
    breakpoints,
    indents,
    typography
};

export type ThemeType = typeof theme;
