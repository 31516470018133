const colors = {
    // Main color
    white: "#ffffff",
    backgroundGray: "#f1f3f4",
    lineGray: "#e4e7ea",
    darkGray: "#9ea3af",
    blueGray: "#798897",
    black: "#1d1d1d",
    deepBlack: "#000000",
    red: "#d61e3d",
    orange: "#ff5842",
    orangeLight: "#FD7E14",
    blue: "#2857e0",

    //Gradients
    orangeGradient: "linear-gradient(135deg, #FEB984 0%, #FF5542 100%)",
    redGradient: "linear-gradient(178.12deg, #FF566D 0%, #D61E3D 100%)",
    blueGradient: "linear-gradient(135deg, #50D1F1 0%, #074FB1 100%)",
    darkGradient: "linear-gradient(135deg, #5F86EC 0%, #7C4CB0 100%)",
    purpleGradient: "linear-gradient(135deg, #5F86EC 0%, #7C4CB0 100%)",

    // Opacity
    whiteOpacity: "rgba(255, 255, 255, 0.2)",
    whiteOpacityTwo: "rgba(255, 255, 255, 0.5)",
    deepWhiteOpacity: "rgba(255, 255, 255, 0.8)",
    blackOpacity: "rgba(29, 29, 29, 0.2)",
    blackOpacityTwo: "rgba(29, 29, 29, 0.5)",
    blackOpacityThree: "rgba(29, 29, 29, 0.6)",
    deepBlackOpacity: "rgba(0, 0, 0, 0.7)",
    redOpacity: "rgba(214, 30, 61, 0.1)",

    // Dropdown vars
    defaultTransparentGray: "#a8a8ac",
    defaultLightgray: "#dee2e9",
    bgGray: "#f7f9fd",
    neutralGray: "#dee2e9",
};

export default colors;
