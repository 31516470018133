import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore } from "redux-persist";

import rootSaga from "./root-saga";
import rootReducer from "./root-reducer";
import {createWrapper} from "next-redux-wrapper";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "core/changeModalState",
          "myGoods/postProduct",
          "myGoods/setPostProductStatus",
          "myGoods/postPleadData",
          "core/postRegistrationForm"
        ],
      },
      thunk: false,
    }),
    sagaMiddleware,
  ],
  devTools: process.env.NODE_ENV !== "production",
});
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

const makeStore = () => store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<RootStore['getState']>;
export type RootStore = ReturnType<typeof makeStore>;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const wrapper = createWrapper<RootStore>(makeStore);