import { SagaIterator } from "redux-saga";
import { all, fork } from "redux-saga/effects";
import coreSaga from "@app/store/core/sagas";
import myGoodsSaga from "@app/store/myGoods/sagas";
import userProfileSaga from "@app/store/userProfile/sagas";

export default function* rootSaga(): SagaIterator {
  yield all([
    fork(coreSaga),
    fork(myGoodsSaga),
    fork(userProfileSaga),
  ]);
}
