import React, { FC, useEffect } from "react";
import * as S from "./styled";
import IconClose from "../../../icons/icon-close.svg";
import { changePolicyModalState } from "@app/store/core/slice";
import {useAppDispatch} from "@app/store";

const PolicyModal: FC = () => {
  const dispatch = useAppDispatch();

  const closeHandler = (event) => {
    dispatch(changePolicyModalState({ visibility: false }));
    event.stopPropagation();
  };


  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);

  return (
    <S.Layout>
      <S.Section>
        <S.Header>
          <S.Title>Политика конфиденциальности</S.Title>
          <S.CloseButton onClick={(event) => closeHandler(event)}>
            <IconClose />
          </S.CloseButton>
        </S.Header>

        <S.DateUpdate>Дата обновления: 22.02.2023</S.DateUpdate>
        <S.Text>
          Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в
          отношении всей информации, которую сайт электронной гарантии «Aqua Warranty», расположенный на доменном имени
          aqua-warranty.ru, может получить о Пользователе во время использования сайта электронной гарантии, программ и
          продуктов.
        </S.Text>
        <S.Subtitle>1. Определение терминов</S.Subtitle>
        <S.List>
          <li>1.1. В настоящей Политике конфиденциальности используются следующие термины:</li>
          <li>
            1.1.1. «Администрация сайта сайта электронной гарантии (далее – Администрация сайта)» – сотрудники,
            уполномоченные на управление сайтом, действующие от имени организации ООО «Сервис Центр», которые
            организуют и (или) осуществляют обработку персональных данных, а также определяют цели обработки
            персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с
            персональными данными.
          </li>
          <li>
            1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или
            определяемому физическому лицу (субъекту персональных данных).
          </li>
          <li>
            1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций),
            совершаемых с использованием средств автоматизации или без использования таких средств с персональными
            данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
            извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование,
            удаление, уничтожение персональных данных.
          </li>
          <li>
            1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим
            доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта
            персональных данных или наличия иного законного основания.
          </li>
          <li>
            1.1.5. «Пользователь сайта электронной гарантии (далее Пользователь)» – лицо, имеющее доступ к Сайту,
            посредством сети Интернет и использующее сайт электронной гарантии.
          </li>
          <li>
            1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере
            пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при
            попытке открыть страницу соответствующего сайта.
          </li>
          <li>1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</li>
        </S.List>
        <S.Subtitle>2. Общие положения</S.Subtitle>
        <S.List>
          <li>
            2.1. Использование Пользователем сайта электронной гарантии означает согласие с настоящей Политикой
            конфиденциальности и условиями обработки персональных данных Пользователя.
          </li>
          <li>
            2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить
            использование сайта электронной гарантии.
          </li>
          <li>
            2.3. Настоящая Политика конфиденциальности применяется только к сайту электронной гарантии -
            aqua-warranty.ru. Сайт электронной гарантии не контролирует и не несет ответственность за сайты третьих лиц,
            на которые Пользователь может перейти по ссылкам, доступным на сайте электронной гарантии.
          </li>
          <li>
            2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта
            электронной гарантии.
          </li>
        </S.List>
        <S.Subtitle>3. Предмет политики конфиденциальности</S.Subtitle>
        <S.List>
          <li>
            3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта электронной
            гарантии по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые
            Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте электронной гарантии или
            при оформлении заявки на сервисное обслуживание.
          </li>
          <li>
            3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности,
            предоставляются Пользователем путём заполнения регистрационной формы на Сайте электронной гарантии “Aqua
            Warranty” в разделах Авторизация и Оформление заказа и включают в себя следующую информацию:
            <ul>
              <li>3.2.1. фамилию, имя, отчество Пользователя;</li>
              <li>3.2.2. контактный телефон Пользователя;</li>
              <li>3.2.3. адрес электронной почты (e-mail);</li>
              <li>3.2.4. адрес и место покупки товара;</li>
              <li>3.2.5. место жительство Пользователя.</li>
            </ul>
          </li>
          <li>
            3.3. Сайт защищает Данные, которые автоматически передаются в процессе просмотра рекламных
            блоков и при посещении страниц, на которых установлен статистический скрипт системы ("пиксель"):
            <ul>
              <li>IP адрес;</li>
              <li>информация из cookies;</li>
              <li>информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</li>
              <li>время доступа;</li>
              <li>адрес страницы, на которой расположен рекламный блок;</li>
              <li>реферер (адрес предыдущей страницы).</li>
            </ul>
          </li>
          <li>
            3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта электронной гарантии, требующим
            авторизации.
          </li>
          <li>
            3.3.2. Сайт электронной гарантии осуществляет сбор статистики об IP-адресах своих посетителей. Данная
            информация используется с целью выявления и решения технических проблем, для контроля законности проводимых
            финансовых платежей.
          </li>
          <li>
            3.4. Любая иная персональная информация, не оговоренная выше (история покупок, используемые браузеры и
            операционные системы и т.д.), подлежит надежному хранению и нераспространению, за исключением случаев,
            предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
          </li>
        </S.List>
        <S.Subtitle>4. Цели сбора персональной информации пользователя</S.Subtitle>
        <S.List>
          <li>
            4.1. Персональные данные Пользователя Администрация сайта электронной гарантии может использовать в целях:
          </li>
          <li>
            4.1.1. Идентификации Пользователя, зарегистрированного на сайте электронной гарантии, для оформления заказа
            и (или) заключения Договора купли-продажи товара дистанционным способом с сайтом электронной гарантии.
          </li>
          <li>4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта электронной гарантии.</li>
          <li>
            4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся
            использования Сайта электронной гарантии, оказания услуг, обработка запросов и заявок от Пользователя.
          </li>
          <li>
            4.1.4. Определения местонахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
          </li>
          <li>4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</li>
          <li>
            4.1.6. Создания учетной записи для совершения покупок, если Пользователь дал согласие на создание учетной
            записи.
          </li>
          <li>
            4.1.7. Уведомления Пользователя Сайта электронной гарантии о статусе условий гарантийного обслуживания
            товара.
          </li>
          <li>
            4.1.8. Обработки и получения платежей, подтверждения налога или налоговых льгот, оспаривания платежа,
            определения права на получение кредитной линии Пользователем.
          </li>
          <li>
            4.1.9. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем,
            связанных с использованием Сайта электронной гарантии.
          </li>
          <li>
            4.1.10. Предоставления Пользователю, с его согласия, обновлений продукции, специальных предложений,
            информации о ценах, новостной рассылки и иных сведений от имени сайта электронной гарантии или от имени
            партнеров сайта электронной гарантии.
          </li>
          <li>4.1.11. Осуществления рекламной деятельности с согласия Пользователя.</li>
          <li>
            4.1.12. Предоставления доступа Пользователю на сайты или сервисы партнеров сайта электронной гарантии с
            целью онлайн-оплаты.
          </li>
        </S.List>
        <S.Subtitle>Способы и сроки обработки персональной информации</S.Subtitle>
        <S.List>
          <li>
            5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным
            способом, в том числе в информационных системах персональных данных с использованием средств автоматизации
            или без использования таких средств.
          </li>
          <li>
            5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим
            лицам, в частности, исполнителям работ, курьерским службам, транспортным компаниям, организациям почтовой
            связи, операторам электросвязи, исключительно в целях выполнения заявки Пользователя, оформленной на Сайте
            электронной гарантии «Aqua Warranty», включая доставку Товара.
          </li>
          <li>
            5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти
            Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.
          </li>
          <li>
            5.4. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате
            или разглашении персональных данных.
          </li>
          <li>
            5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной
            информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования,
            копирования, распространения, а также от иных неправомерных действий третьих лиц.
          </li>
          <li>
            5.6. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков
            или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
          </li>
        </S.List>
        <S.Subtitle>Обязательства сторон</S.Subtitle>
        <S.List>
          <li>
            6.1. Пользователь обязан:
            <ul>
              <li>
                6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом электронной
                гарантии.
              </li>
              <li>
                6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной
                информации.
              </li>
            </ul>
          </li>
          <li>
            6.2. Администрация сайта обязана:
            <ul>
              <li>
                6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики
                конфиденциальности.
              </li>
              <li>
                6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного
                письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо
                разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п.
                5.2. и 5.3. настоящей Политики Конфиденциальности.
              </li>
              <li>
                6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя
                согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.
              </li>
              <li>
                6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с
                момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа
                по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных
                персональных данных или неправомерных действий.
              </li>
            </ul>
          </li>
        </S.List>
        <S.Subtitle>7. Ответственность сторон</S.Subtitle>
        <S.List>
          <li>
            7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые
            Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством
            Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики
            Конфиденциальности.
          </li>
          <li>
            7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт
            ответственность, если данная конфиденциальная информация:
            <ul>
              <li>7.2.1. Стала публичным достоянием до её утраты или разглашения.</li>
              <li>7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.</li>
              <li>7.2.3. Была разглашена с согласия Пользователя.</li>
            </ul>
          </li>
        </S.List>
        <S.Subtitle>8. Разрешение споров</S.Subtitle>
        <S.List>
          <li>
            8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта электронной
            гарантии и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о
            добровольном урегулировании спора).
          </li>
          <li>
            8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии письменно уведомляет
            заявителя претензии о результатах рассмотрения претензии.
          </li>
          <li>
            8.3. При недостижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с
            действующим законодательством Российской Федерации.
          </li>
          <li>
            8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта
            применяется действующее законодательство Российской Федерации.
          </li>
        </S.List>
        <S.Subtitle>9. Дополнительные условия</S.Subtitle>
        <S.List>
          <li>
            9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия
            Пользователя.
          </li>
          <li>
            9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте электронной
            гарантии, если иное не предусмотрено новой редакцией Политики конфиденциальности.
          </li>
          <li>
            9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по адресу
            mail@aqua-warranty.ru
          </li>
          <li>9.4. Действующая Политика конфиденциальности размещена на странице по адресу aqua-warranty.ru/policy/</li>
        </S.List>
      </S.Section>
    </S.Layout>
  );
};

export default PolicyModal;
