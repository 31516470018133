// tslint:disable
/**
 * Aqua Warranty API
 * API проекта электронной гарантии
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sergei.podkovyrkin@aquaart.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthCredentialsRequestDTO
 */
export interface AuthCredentialsRequestDTO {
    /**
     * Номер телефона
     * @type {number}
     * @memberof AuthCredentialsRequestDTO
     */
    phone: number;
    /**
     * Номер телефона (отправляем пустоту)
     * @type {string}
     * @memberof AuthCredentialsRequestDTO
     */
    secret_phone?: string;
    /**
     * Ответ google рекапчи для клиента
     * @type {string}
     * @memberof AuthCredentialsRequestDTO
     */
    google_recaptcha_response: string;
    /**
     * 
     * @type {string}
     * @memberof AuthCredentialsRequestDTO
     */
    finger_print?: string;
}
/**
 * 
 * @export
 * @interface BadRequestResponseDTO
 */
export interface BadRequestResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof BadRequestResponseDTO
     */
    message: string;
}
/**
 * 
 * @export
 * @interface ConfirmationAuthCredentialsRequestDTO
 */
export interface ConfirmationAuthCredentialsRequestDTO {
    /**
     * Идентификатор подтверждения
     * @type {number}
     * @memberof ConfirmationAuthCredentialsRequestDTO
     */
    confirmation_id: number;
    /**
     * Код подтверждения
     * @type {string}
     * @memberof ConfirmationAuthCredentialsRequestDTO
     */
    code: string;
}
/**
 * 
 * @export
 * @interface ConfirmationLoginResponseDTO
 */
export interface ConfirmationLoginResponseDTO {
    /**
     * Идентификатор подтверждения
     * @type {number}
     * @memberof ConfirmationLoginResponseDTO
     */
    confirmation_id: number;
    /**
     * Пользователь верифицирован?
     * @type {boolean}
     * @memberof ConfirmationLoginResponseDTO
     */
    is_user_verified: boolean;
}
/**
 * 
 * @export
 * @interface CreateProductRequestDTO
 */
export interface CreateProductRequestDTO {
    /**
     * Идентификатор товара
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    product_guid: string;
    /**
     * Дата покупки
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    purchase_at: string;
    /**
     * Место покупки
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    purchase_shop_name: string;
    /**
     * 
     * @type {UserAddressDTO}
     * @memberof CreateProductRequestDTO
     */
    purchase_address: UserAddressDTO | null;
    /**
     * Файл подтверждения покупки. image/doc(x)/xls(x)/pdf
     * @type {any}
     * @memberof CreateProductRequestDTO
     */
    purchase_document: any;
    /**
     * Промокод
     * @type {string}
     * @memberof CreateProductRequestDTO
     */
    promocode: string;
}
/**
 * 
 * @export
 * @interface CreateReclamationRequest
 */
export interface CreateReclamationRequest {
    /**
     * Идентификатор гарантийного товара
     * @type {string}
     * @memberof CreateReclamationRequest
     */
    warranty_product_id: string;
    /**
     * Тип обращения
     * @type {string}
     * @memberof CreateReclamationRequest
     */
    type: CreateReclamationRequestTypeEnum;
    /**
     * Суть обращения (текст обращения)
     * @type {string}
     * @memberof CreateReclamationRequest
     */
    content: string;
    /**
     * image/doc(x)/xls(x)/pdf. max 4шт по 10мб
     * @type {any}
     * @memberof CreateReclamationRequest
     */
    documents: any;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateReclamationRequestTypeEnum {
    RECLAMATION = 'RECLAMATION',
    SERVICE = 'SERVICE'
}

/**
 * 
 * @export
 * @interface ImageDTO
 */
export interface ImageDTO {
    /**
     * Объект содержащий наборы изображений для ПК экранов
     * @type {ImageSetDTO}
     * @memberof ImageDTO
     */
    desktop: ImageSetDTO;
    /**
     * Объект содержащий наборы изображений для планшетных экранов
     * @type {ImageSetDTO}
     * @memberof ImageDTO
     */
    tablet: ImageSetDTO;
    /**
     * Объект содержащий наборы изображений для мобильных экранов
     * @type {ImageSetDTO}
     * @memberof ImageDTO
     */
    mobile: ImageSetDTO;
    /**
     * Объект содержащий наборы изображений в максимальном разрешении (для галлереи)
     * @type {ImageSetDTO}
     * @memberof ImageDTO
     */
    fullscreen?: ImageSetDTO;
}
/**
 * 
 * @export
 * @interface ImageSetDTO
 */
export interface ImageSetDTO {
    /**
     * Ссылка на изображение в jpg x1
     * @type {string}
     * @memberof ImageSetDTO
     */
    jpg_default: string;
    /**
     * Ссылка на изображение в jpg x2
     * @type {string}
     * @memberof ImageSetDTO
     */
    jpg_double: string;
    /**
     * Ссылка на изображение в webp x1
     * @type {string}
     * @memberof ImageSetDTO
     */
    webp_default: string;
    /**
     * Ссылка на изображение в webp x2
     * @type {string}
     * @memberof ImageSetDTO
     */
    webp_double: string;
}
/**
 * 
 * @export
 * @interface PatchUserRequest
 */
export interface PatchUserRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchUserRequest
     */
    phone: number;
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    birthday_date: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    first_name: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    second_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    last_name: string;
    /**
     * 
     * @type {UserAddressDTO}
     * @memberof PatchUserRequest
     */
    address: UserAddressDTO | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchUserRequest
     */
    additional_phones?: Array<number>;
}
/**
 * 
 * @export
 * @interface PreviewImageDTO
 */
export interface PreviewImageDTO {
    /**
     * 
     * @type {ImageSetDTO}
     * @memberof PreviewImageDTO
     */
    desktop: ImageSetDTO;
    /**
     * 
     * @type {ImageSetDTO}
     * @memberof PreviewImageDTO
     */
    tablet: ImageSetDTO;
    /**
     * 
     * @type {ImageSetDTO}
     * @memberof PreviewImageDTO
     */
    mobile: ImageSetDTO;
}
/**
 * 
 * @export
 * @interface ProductAwardDTO
 */
export interface ProductAwardDTO {
    /**
     * Идентификатор награды
     * @type {number}
     * @memberof ProductAwardDTO
     */
    id: number;
    /**
     * Наименование награды
     * @type {string}
     * @memberof ProductAwardDTO
     */
    title: string;
    /**
     * Ссылка на иконку
     * @type {string}
     * @memberof ProductAwardDTO
     */
    image_path: string;
}
/**
 * 
 * @export
 * @interface ProductBrandDTO
 */
export interface ProductBrandDTO {
    /**
     * ID бренда
     * @type {string}
     * @memberof ProductBrandDTO
     */
    guid: string;
    /**
     * Наименование бренда
     * @type {string}
     * @memberof ProductBrandDTO
     */
    name: string;
    /**
     * Уникальный slug бренда
     * @type {string}
     * @memberof ProductBrandDTO
     */
    slug: string;
    /**
     * Ссылка на картинку бренда
     * @type {string}
     * @memberof ProductBrandDTO
     */
    image_path?: string;
}
/**
 * 
 * @export
 * @interface ProductCollectionDTO
 */
export interface ProductCollectionDTO {
    /**
     * ID коллекции
     * @type {string}
     * @memberof ProductCollectionDTO
     */
    guid: string;
    /**
     * Наименование коллекции
     * @type {string}
     * @memberof ProductCollectionDTO
     */
    name: string;
    /**
     * Уникальный slug коллекции
     * @type {string}
     * @memberof ProductCollectionDTO
     */
    slug: string;
}
/**
 * 
 * @export
 * @interface ProductContentGridDTO
 */
export interface ProductContentGridDTO {
    /**
     * Глобальный идентификатор товара
     * @type {string}
     * @memberof ProductContentGridDTO
     */
    guid: string;
    /**
     * Артикул товара
     * @type {string}
     * @memberof ProductContentGridDTO
     */
    article: string;
    /**
     * Штрихкод товара
     * @type {string}
     * @memberof ProductContentGridDTO
     */
    barcode: string;
    /**
     * Наименование товара
     * @type {string}
     * @memberof ProductContentGridDTO
     */
    name: string;
    /**
     * Наименование бренда в 1C
     * @type {string}
     * @memberof ProductContentGridDTO
     */
    _1c_brand: string;
    /**
     * Наименование коллекции в 1C
     * @type {string}
     * @memberof ProductContentGridDTO
     */
    _1c_collection: string;
    /**
     * 
     * @type {ProductBrandDTO}
     * @memberof ProductContentGridDTO
     */
    brand: ProductBrandDTO | null;
    /**
     * 
     * @type {ProductCollectionDTO}
     * @memberof ProductContentGridDTO
     */
    collection: ProductCollectionDTO | null;
    /**
     * 
     * @type {Array<PreviewImageDTO>}
     * @memberof ProductContentGridDTO
     */
    images: Array<PreviewImageDTO>;
}
/**
 * 
 * @export
 * @interface ProductDetailDTO
 */
export interface ProductDetailDTO {
    /**
     * Идентификатор гарантийного товара
     * @type {string}
     * @memberof ProductDetailDTO
     */
    id: string;
    /**
     * Глобальный идентификатор товара
     * @type {string}
     * @memberof ProductDetailDTO
     */
    product_guid: string;
    /**
     * Дата окончания гарантии по умолчанию
     * @type {string}
     * @memberof ProductDetailDTO
     */
    default_warranty_expires_at: string;
    /**
     * Дата окончания расширенной гарантии
     * @type {string}
     * @memberof ProductDetailDTO
     */
    extended_warranty_expires_at: string | null;
    /**
     * Условия гарантии
     * @type {string}
     * @memberof ProductDetailDTO
     */
    warranty_rule?: string;
    /**
     * 
     * @type {ProductDetailDTOPurchase}
     * @memberof ProductDetailDTO
     */
    purchase: ProductDetailDTOPurchase;
    /**
     * 
     * @type {ProductDetailDTOService}
     * @memberof ProductDetailDTO
     */
    service: ProductDetailDTOService;
    /**
     * 
     * @type {ProductDetailDTOProductSet}
     * @memberof ProductDetailDTO
     */
    product_set: ProductDetailDTOProductSet;
    /**
     * Наименование товара
     * @type {string}
     * @memberof ProductDetailDTO
     */
    name: string;
    /**
     * Артикул товара
     * @type {string}
     * @memberof ProductDetailDTO
     */
    article: string;
    /**
     * Наименование коллекции в 1C
     * @type {string}
     * @memberof ProductDetailDTO
     */
    _1c_collection?: string;
    /**
     * 
     * @type {ProductCollectionDTO}
     * @memberof ProductDetailDTO
     */
    collection?: ProductCollectionDTO | null;
    /**
     * Наименование бренда в 1C
     * @type {string}
     * @memberof ProductDetailDTO
     */
    _1c_brand?: string;
    /**
     * 
     * @type {ProductBrandDTO}
     * @memberof ProductDetailDTO
     */
    brand: ProductBrandDTO | null;
    /**
     * Элементы галлереи
     * @type {Array<ProductGalleryItemDTO>}
     * @memberof ProductDetailDTO
     */
    gallery: Array<ProductGalleryItemDTO>;
    /**
     * Награды
     * @type {Array<ProductAwardDTO>}
     * @memberof ProductDetailDTO
     */
    awards: Array<ProductAwardDTO>;
    /**
     * Комплектация (список)
     * @type {Array<string>}
     * @memberof ProductDetailDTO
     */
    equipment: Array<string>;
}
/**
 * 
 * @export
 * @interface ProductDetailDTOProductSet
 */
export interface ProductDetailDTOProductSet {
    /**
     * 
     * @type {Array<ProductGridItemDTO>}
     * @memberof ProductDetailDTOProductSet
     */
    warranties: Array<ProductGridItemDTO>;
    /**
     * 
     * @type {Array<ProductSetItemDTO>}
     * @memberof ProductDetailDTOProductSet
     */
    contents: Array<ProductSetItemDTO>;
}
/**
 * 
 * @export
 * @interface ProductDetailDTOPurchase
 */
export interface ProductDetailDTOPurchase {
    /**
     * Дата покупки
     * @type {string}
     * @memberof ProductDetailDTOPurchase
     */
    purchase_at: string;
    /**
     * Место покупки
     * @type {string}
     * @memberof ProductDetailDTOPurchase
     */
    purchase_shop_name: string;
    /**
     * Адрес покупки
     * @type {string}
     * @memberof ProductDetailDTOPurchase
     */
    purchase_address: string;
    /**
     * Ссылка на документ
     * @type {string}
     * @memberof ProductDetailDTOPurchase
     */
    purchase_document_link: string;
}
/**
 * 
 * @export
 * @interface ProductDetailDTOService
 */
export interface ProductDetailDTOService {
    /**
     * Идентификатор услуги
     * @type {string}
     * @memberof ProductDetailDTOService
     */
    guid: string;
    /**
     * Стоимость установки от
     * @type {number}
     * @memberof ProductDetailDTOService
     */
    cost_from: number;
    /**
     * Миниамальное кол-во дней (срок)
     * @type {number}
     * @memberof ProductDetailDTOService
     */
    days_amount_from: number;
    /**
     * Максимальное кол-во дней (срок)
     * @type {number}
     * @memberof ProductDetailDTOService
     */
    days_amount_to: number;
}
/**
 * 
 * @export
 * @interface ProductGalleryItemDTO
 */
export interface ProductGalleryItemDTO {
    /**
     * Тип элемента галлереи
     * @type {string}
     * @memberof ProductGalleryItemDTO
     */
    type: ProductGalleryItemDTOTypeEnum;
    /**
     * Содержимое элемента галлереи
     * @type {ProductGalleryItemImageDTO | ProductGalleryItemYouTubeDTO | ProductGalleryItemVideoDTO}
     * @memberof ProductGalleryItemDTO
     */
    data: ProductGalleryItemImageDTO | ProductGalleryItemYouTubeDTO | ProductGalleryItemVideoDTO;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductGalleryItemDTOTypeEnum {
    YOUTUBE = 'YOUTUBE',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO'
}

/**
 * 
 * @export
 * @interface ProductGalleryItemImageDTO
 */
export interface ProductGalleryItemImageDTO {
    /**
     * Ссылки на изображения
     * @type {ImageDTO}
     * @memberof ProductGalleryItemImageDTO
     */
    images: ImageDTO;
}
/**
 * 
 * @export
 * @interface ProductGalleryItemVideoDTO
 */
export interface ProductGalleryItemVideoDTO {
    /**
     * Ссылки на изображения
     * @type {ImageDTO}
     * @memberof ProductGalleryItemVideoDTO
     */
    images: ImageDTO;
    /**
     * Ссылка на видео
     * @type {string}
     * @memberof ProductGalleryItemVideoDTO
     */
    rel: string;
}
/**
 * 
 * @export
 * @interface ProductGalleryItemYouTubeDTO
 */
export interface ProductGalleryItemYouTubeDTO {
    /**
     * Ссылки на изображения
     * @type {ImageDTO}
     * @memberof ProductGalleryItemYouTubeDTO
     */
    images: ImageDTO;
    /**
     * Ссылка на видео
     * @type {string}
     * @memberof ProductGalleryItemYouTubeDTO
     */
    rel: string;
}
/**
 * 
 * @export
 * @interface ProductGridItemDTO
 */
export interface ProductGridItemDTO {
    /**
     * Идентификатор гарантийного товара
     * @type {string}
     * @memberof ProductGridItemDTO
     */
    id: string;
    /**
     * Дата окончания гарантии по умолчанию
     * @type {string}
     * @memberof ProductGridItemDTO
     */
    default_warranty_expires_at: string;
    /**
     * Дата окончания расширенной гарантии
     * @type {string}
     * @memberof ProductGridItemDTO
     */
    extended_warranty_expires_at: string | null;
    /**
     * Глобальный дентификатор товара
     * @type {string}
     * @memberof ProductGridItemDTO
     */
    product_guid: string;
}
/**
 * 
 * @export
 * @interface ProductListResponseDTO
 */
export interface ProductListResponseDTO {
    /**
     * Всего элементов
     * @type {number}
     * @memberof ProductListResponseDTO
     */
    total: number;
    /**
     * Кол-во элементов за итерацию
     * @type {number}
     * @memberof ProductListResponseDTO
     */
    count: number;
    /**
     * 
     * @type {ProductListResponseDTOData}
     * @memberof ProductListResponseDTO
     */
    data: ProductListResponseDTOData;
}
/**
 * 
 * @export
 * @interface ProductListResponseDTOData
 */
export interface ProductListResponseDTOData {
    /**
     * Список гарантийных товаров
     * @type {Array<ProductGridItemDTO>}
     * @memberof ProductListResponseDTOData
     */
    warranty_product_list: Array<ProductGridItemDTO>;
    /**
     * Список контента для товаров
     * @type {Array<ProductContentGridDTO>}
     * @memberof ProductListResponseDTOData
     */
    content_product_list: Array<ProductContentGridDTO>;
}
/**
 * 
 * @export
 * @interface ProductMontageItemDTO
 */
export interface ProductMontageItemDTO {
    /**
     * Наименование файла
     * @type {string}
     * @memberof ProductMontageItemDTO
     */
    title: string;
    /**
     * Подзаголовок
     * @type {string}
     * @memberof ProductMontageItemDTO
     */
    sub_title: string;
    /**
     * Ссылка на файл
     * @type {string}
     * @memberof ProductMontageItemDTO
     */
    path: string;
}
/**
 * 
 * @export
 * @interface ProductSetItemDTO
 */
export interface ProductSetItemDTO {
    /**
     * Глобальный идентификатор товара
     * @type {string}
     * @memberof ProductSetItemDTO
     */
    product_guid: string;
    /**
     * Наименование товара
     * @type {string}
     * @memberof ProductSetItemDTO
     */
    name: string;
    /**
     * Артикул товара
     * @type {string}
     * @memberof ProductSetItemDTO
     */
    article: string;
    /**
     * 
     * @type {ProductMontageItemDTO}
     * @memberof ProductSetItemDTO
     */
    instruction?: ProductMontageItemDTO | null;
}
/**
 * 
 * @export
 * @interface ProductWarrantyGridDTO
 */
export interface ProductWarrantyGridDTO {
    /**
     * Глобальный идентификатор товара
     * @type {string}
     * @memberof ProductWarrantyGridDTO
     */
    guid: string;
    /**
     * Срок гарантии в месяцах
     * @type {number}
     * @memberof ProductWarrantyGridDTO
     */
    warranty_count: number;
    /**
     * Дата окончания гарантии при расчете от сегодняшнего дня
     * @type {string}
     * @memberof ProductWarrantyGridDTO
     */
    warranty_expires_at: string;
    /**
     * Условия гарантии (HTML)
     * @type {string}
     * @memberof ProductWarrantyGridDTO
     */
    warranty_rule: string;
}
/**
 * 
 * @export
 * @interface RefreshTokenRequestBodyDTO
 */
export interface RefreshTokenRequestBodyDTO {
    /**
     * Железный отпечаток
     * @type {string}
     * @memberof RefreshTokenRequestBodyDTO
     */
    finger_print?: string;
}
/**
 * 
 * @export
 * @interface ResendCodeRequestDTO
 */
export interface ResendCodeRequestDTO {
    /**
     * Идентификатор подтверждения
     * @type {number}
     * @memberof ResendCodeRequestDTO
     */
    confirmation_id: number;
    /**
     * Ответ google рекапчи для клиента
     * @type {string}
     * @memberof ResendCodeRequestDTO
     */
    google_recaptcha_response: string;
}
/**
 * 
 * @export
 * @interface ResetConfirmationCodeResponseDTO
 */
export interface ResetConfirmationCodeResponseDTO {
    /**
     * Количество оставшихся попыток
     * @type {number}
     * @memberof ResetConfirmationCodeResponseDTO
     */
    retries: number;
}
/**
 * 
 * @export
 * @interface SearchProductListResponseDTO
 */
export interface SearchProductListResponseDTO {
    /**
     * Всего элементов
     * @type {number}
     * @memberof SearchProductListResponseDTO
     */
    total: number;
    /**
     * Кол-во элементов за итерацию
     * @type {number}
     * @memberof SearchProductListResponseDTO
     */
    count: number;
    /**
     * 
     * @type {SearchProductListResponseDTOData}
     * @memberof SearchProductListResponseDTO
     */
    data: SearchProductListResponseDTOData;
}
/**
 * 
 * @export
 * @interface SearchProductListResponseDTOData
 */
export interface SearchProductListResponseDTOData {
    /**
     * Список контента для товаров
     * @type {Array<ProductContentGridDTO>}
     * @memberof SearchProductListResponseDTOData
     */
    content_product_list: Array<ProductContentGridDTO>;
    /**
     * Информация о гарантии для товаров
     * @type {Array<ProductWarrantyGridDTO>}
     * @memberof SearchProductListResponseDTOData
     */
    warranty_product_list?: Array<ProductWarrantyGridDTO>;
}
/**
 * 
 * @export
 * @interface TokenResponseDTO
 */
export interface TokenResponseDTO {
    /**
     * Основной токен
     * @type {string}
     * @memberof TokenResponseDTO
     */
    access_token: string;
    /**
     * Тип
     * @type {string}
     * @memberof TokenResponseDTO
     */
    type: string;
    /**
     * TTL
     * @type {number}
     * @memberof TokenResponseDTO
     */
    expired_in: number;
}
/**
 * 
 * @export
 * @interface UserAccessResponseDTO
 */
export interface UserAccessResponseDTO {
    /**
     * 
     * @type {UserAccessResponseDTORole}
     * @memberof UserAccessResponseDTO
     */
    role: UserAccessResponseDTORole;
    /**
     * 
     * @type {UserAccessResponseDTOComponents}
     * @memberof UserAccessResponseDTO
     */
    components: UserAccessResponseDTOComponents;
}
/**
 * 
 * @export
 * @interface UserAccessResponseDTOComponents
 */
export interface UserAccessResponseDTOComponents {
    /**
     * мои товары
     * @type {boolean}
     * @memberof UserAccessResponseDTOComponents
     */
    products: boolean;
    /**
     * акции
     * @type {boolean}
     * @memberof UserAccessResponseDTOComponents
     */
    news: boolean;
    /**
     * 
     * @type {UserAccessResponseDTOComponentsCan}
     * @memberof UserAccessResponseDTOComponents
     */
    can: UserAccessResponseDTOComponentsCan | null;
}
/**
 * 
 * @export
 * @interface UserAccessResponseDTOComponentsCan
 */
export interface UserAccessResponseDTOComponentsCan {
    /**
     * Может добавить товар в мои товары
     * @type {boolean}
     * @memberof UserAccessResponseDTOComponentsCan
     */
    make_product: boolean;
    /**
     * Может сделать запрос в СЦ
     * @type {boolean}
     * @memberof UserAccessResponseDTOComponentsCan
     */
    make_sc_request: boolean;
    /**
     * Может получить расширенную гарантию
     * @type {boolean}
     * @memberof UserAccessResponseDTOComponentsCan
     */
    get_extra_warranty: boolean;
}
/**
 * 
 * @export
 * @interface UserAccessResponseDTORole
 */
export interface UserAccessResponseDTORole {
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponseDTORole
     */
    code?: UserAccessResponseDTORoleCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserAccessResponseDTORole
     */
    name?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserAccessResponseDTORoleCodeEnum {
    BUYER = 'BUYER'
}

/**
 * Объект адреса клиента
 * @export
 * @interface UserAddressDTO
 */
export interface UserAddressDTO {
    /**
     * Кадастровый номер
     * @type {string}
     * @memberof UserAddressDTO
     */
    kladr_id: string;
    /**
     * индекс
     * @type {number}
     * @memberof UserAddressDTO
     */
    zip_code: number;
    /**
     * Полный адрес
     * @type {string}
     * @memberof UserAddressDTO
     */
    plain_address: string;
    /**
     * lat
     * @type {number}
     * @memberof UserAddressDTO
     */
    lat?: number;
    /**
     * lon
     * @type {number}
     * @memberof UserAddressDTO
     */
    lon?: number;
}
/**
 * 
 * @export
 * @interface UserResponseDTO
 */
export interface UserResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    id: string;
    /**
     * Пользователь верифицирован?
     * @type {boolean}
     * @memberof UserResponseDTO
     */
    is_user_verified: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    email: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserResponseDTO
     */
    phone: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    birthday_date: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    first_name: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    second_name: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserResponseDTO
     */
    last_name: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserResponseDTO
     */
    additional_phones: Array<number>;
    /**
     * 
     * @type {UserAccessResponseDTO}
     * @memberof UserResponseDTO
     */
    access: UserAccessResponseDTO | null;
    /**
     * 
     * @type {UserAddressDTO}
     * @memberof UserResponseDTO
     */
    address: UserAddressDTO | null;
}
/**
 * 
 * @export
 * @interface ValidationErrorResponseDTO
 */
export interface ValidationErrorResponseDTO {
    /**
     * Короткое описание ошибки
     * @type {string}
     * @memberof ValidationErrorResponseDTO
     */
    message: string;
    /**
     * Тело ошибки
     * @type {object}
     * @memberof ValidationErrorResponseDTO
     */
    data: object;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Метод получения токена
         * @summary Подтверждение входа
         * @param {ConfirmationAuthCredentialsRequestDTO} confirmationAuthCredentialsRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationLoginByPhone: async (confirmationAuthCredentialsRequestDTO: ConfirmationAuthCredentialsRequestDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationAuthCredentialsRequestDTO' is not null or undefined
            if (confirmationAuthCredentialsRequestDTO === null || confirmationAuthCredentialsRequestDTO === undefined) {
                throw new RequiredError('confirmationAuthCredentialsRequestDTO','Required parameter confirmationAuthCredentialsRequestDTO was null or undefined when calling confirmationLoginByPhone.');
            }
            const localVarPath = `/api/v1/auth/login-confirm`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof confirmationAuthCredentialsRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(confirmationAuthCredentialsRequestDTO !== undefined ? confirmationAuthCredentialsRequestDTO : {}) : (confirmationAuthCredentialsRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод получения кода подтверждения входа в ЛК
         * @summary Вход в лк
         * @param {AuthCredentialsRequestDTO} authCredentialsRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginByPhone: async (authCredentialsRequestDTO: AuthCredentialsRequestDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authCredentialsRequestDTO' is not null or undefined
            if (authCredentialsRequestDTO === null || authCredentialsRequestDTO === undefined) {
                throw new RequiredError('authCredentialsRequestDTO','Required parameter authCredentialsRequestDTO was null or undefined when calling loginByPhone.');
            }
            const localVarPath = `/api/v1/auth/login-by-phone`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof authCredentialsRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(authCredentialsRequestDTO !== undefined ? authCredentialsRequestDTO : {}) : (authCredentialsRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод деавторизации
         * @summary Выход из лк
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeLogout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/logout`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод обновления токена
         * @summary Обновить Токен
         * @param {RefreshTokenRequestBodyDTO} [refreshTokenRequestBodyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeRefresh: async (refreshTokenRequestBodyDTO?: RefreshTokenRequestBodyDTO, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/auth/refresh`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof refreshTokenRequestBodyDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(refreshTokenRequestBodyDTO !== undefined ? refreshTokenRequestBodyDTO : {}) : (refreshTokenRequestBodyDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод получения токена
         * @summary выслать код для входа снова
         * @param {ResendCodeRequestDTO} resendCodeRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationCode: async (resendCodeRequestDTO: ResendCodeRequestDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendCodeRequestDTO' is not null or undefined
            if (resendCodeRequestDTO === null || resendCodeRequestDTO === undefined) {
                throw new RequiredError('resendCodeRequestDTO','Required parameter resendCodeRequestDTO was null or undefined when calling resendConfirmationCode.');
            }
            const localVarPath = `/api/v1/auth/resend-confirmation-code`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof resendCodeRequestDTO !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(resendCodeRequestDTO !== undefined ? resendCodeRequestDTO : {}) : (resendCodeRequestDTO || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Метод получения токена
         * @summary Подтверждение входа
         * @param {ConfirmationAuthCredentialsRequestDTO} confirmationAuthCredentialsRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmationLoginByPhone(confirmationAuthCredentialsRequestDTO: ConfirmationAuthCredentialsRequestDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseDTO>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).confirmationLoginByPhone(confirmationAuthCredentialsRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Метод получения кода подтверждения входа в ЛК
         * @summary Вход в лк
         * @param {AuthCredentialsRequestDTO} authCredentialsRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginByPhone(authCredentialsRequestDTO: AuthCredentialsRequestDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmationLoginResponseDTO>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).loginByPhone(authCredentialsRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Метод деавторизации
         * @summary Выход из лк
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeLogout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).makeLogout(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Метод обновления токена
         * @summary Обновить Токен
         * @param {RefreshTokenRequestBodyDTO} [refreshTokenRequestBodyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeRefresh(refreshTokenRequestBodyDTO?: RefreshTokenRequestBodyDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseDTO>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).makeRefresh(refreshTokenRequestBodyDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Метод получения токена
         * @summary выслать код для входа снова
         * @param {ResendCodeRequestDTO} resendCodeRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendConfirmationCode(resendCodeRequestDTO: ResendCodeRequestDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResetConfirmationCodeResponseDTO>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).resendConfirmationCode(resendCodeRequestDTO, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Метод получения токена
         * @summary Подтверждение входа
         * @param {ConfirmationAuthCredentialsRequestDTO} confirmationAuthCredentialsRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationLoginByPhone(confirmationAuthCredentialsRequestDTO: ConfirmationAuthCredentialsRequestDTO, options?: any): AxiosPromise<TokenResponseDTO> {
            return AuthApiFp(configuration).confirmationLoginByPhone(confirmationAuthCredentialsRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Метод получения кода подтверждения входа в ЛК
         * @summary Вход в лк
         * @param {AuthCredentialsRequestDTO} authCredentialsRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginByPhone(authCredentialsRequestDTO: AuthCredentialsRequestDTO, options?: any): AxiosPromise<ConfirmationLoginResponseDTO> {
            return AuthApiFp(configuration).loginByPhone(authCredentialsRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Метод деавторизации
         * @summary Выход из лк
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeLogout(options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).makeLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * Метод обновления токена
         * @summary Обновить Токен
         * @param {RefreshTokenRequestBodyDTO} [refreshTokenRequestBodyDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeRefresh(refreshTokenRequestBodyDTO?: RefreshTokenRequestBodyDTO, options?: any): AxiosPromise<TokenResponseDTO> {
            return AuthApiFp(configuration).makeRefresh(refreshTokenRequestBodyDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Метод получения токена
         * @summary выслать код для входа снова
         * @param {ResendCodeRequestDTO} resendCodeRequestDTO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmationCode(resendCodeRequestDTO: ResendCodeRequestDTO, options?: any): AxiosPromise<ResetConfirmationCodeResponseDTO> {
            return AuthApiFp(configuration).resendConfirmationCode(resendCodeRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Метод получения токена
     * @summary Подтверждение входа
     * @param {ConfirmationAuthCredentialsRequestDTO} confirmationAuthCredentialsRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public confirmationLoginByPhone(confirmationAuthCredentialsRequestDTO: ConfirmationAuthCredentialsRequestDTO, options?: any) {
        return AuthApiFp(this.configuration).confirmationLoginByPhone(confirmationAuthCredentialsRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Метод получения кода подтверждения входа в ЛК
     * @summary Вход в лк
     * @param {AuthCredentialsRequestDTO} authCredentialsRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginByPhone(authCredentialsRequestDTO: AuthCredentialsRequestDTO, options?: any) {
        return AuthApiFp(this.configuration).loginByPhone(authCredentialsRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Метод деавторизации
     * @summary Выход из лк
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public makeLogout(options?: any) {
        return AuthApiFp(this.configuration).makeLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Метод обновления токена
     * @summary Обновить Токен
     * @param {RefreshTokenRequestBodyDTO} [refreshTokenRequestBodyDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public makeRefresh(refreshTokenRequestBodyDTO?: RefreshTokenRequestBodyDTO, options?: any) {
        return AuthApiFp(this.configuration).makeRefresh(refreshTokenRequestBodyDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Метод получения токена
     * @summary выслать код для входа снова
     * @param {ResendCodeRequestDTO} resendCodeRequestDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resendConfirmationCode(resendCodeRequestDTO: ResendCodeRequestDTO, options?: any) {
        return AuthApiFp(this.configuration).resendConfirmationCode(resendCodeRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Добавить гарантийный товар
         * @summary Добавить гарантийный товар
         * @param {string} productGuid Идентификатор товара
         * @param {string} purchaseAt Дата покупки
         * @param {string} purchaseShopName Место покупки
         * @param {UserAddressDTO} purchaseAddress 
         * @param {any} purchaseDocument Файл подтверждения покупки. image/doc(x)/xls(x)/pdf
         * @param {string} promocode Промокод
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (productGuid: string, purchaseAt: string, purchaseShopName: string, purchaseAddress: UserAddressDTO, purchaseDocument: any, promocode: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productGuid' is not null or undefined
            if (productGuid === null || productGuid === undefined) {
                throw new RequiredError('productGuid','Required parameter productGuid was null or undefined when calling createProduct.');
            }
            // verify required parameter 'purchaseAt' is not null or undefined
            if (purchaseAt === null || purchaseAt === undefined) {
                throw new RequiredError('purchaseAt','Required parameter purchaseAt was null or undefined when calling createProduct.');
            }
            // verify required parameter 'purchaseShopName' is not null or undefined
            if (purchaseShopName === null || purchaseShopName === undefined) {
                throw new RequiredError('purchaseShopName','Required parameter purchaseShopName was null or undefined when calling createProduct.');
            }
            // verify required parameter 'purchaseAddress' is not null or undefined
            if (purchaseAddress === null || purchaseAddress === undefined) {
                throw new RequiredError('purchaseAddress','Required parameter purchaseAddress was null or undefined when calling createProduct.');
            }
            // verify required parameter 'purchaseDocument' is not null or undefined
            if (purchaseDocument === null || purchaseDocument === undefined) {
                throw new RequiredError('purchaseDocument','Required parameter purchaseDocument was null or undefined when calling createProduct.');
            }
            // verify required parameter 'promocode' is not null or undefined
            if (promocode === null || promocode === undefined) {
                throw new RequiredError('promocode','Required parameter promocode was null or undefined when calling createProduct.');
            }
            const localVarPath = `/api/v1/products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (productGuid !== undefined) { 
                localVarFormParams.append('product_guid', productGuid as any);
            }
    
            if (purchaseAt !== undefined) { 
                localVarFormParams.append('purchase_at', purchaseAt as any);
            }
    
            if (purchaseShopName !== undefined) { 
                localVarFormParams.append('purchase_shop_name', purchaseShopName as any);
            }
    
            if (purchaseAddress !== undefined) { 
                localVarFormParams.append('purchase_address', purchaseAddress as any);
            }
    
            if (purchaseDocument !== undefined) { 
                localVarFormParams.append('purchase_document', purchaseDocument as any);
            }
    
            if (promocode !== undefined) { 
                localVarFormParams.append('promocode', promocode as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод позволяет получить подробную информацию о товаре.
         * @summary Товар
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetail: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getProductDetail.');
            }
            const localVarPath = `/api/v1/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод позволяет получить список гарантийных товаров.
         * @summary Товары
         * @param {number} [pageSize] Кол-во элементов на странице
         * @param {number} [pageNumber] Номер страницы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductList: async (pageSize?: number, pageNumber?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод позволяет получить список гарантийных товаров.
         * @summary Поиск товаров
         * @param {string} q Артикул или ШК
         * @param {number} [pageSize] Кол-во элементов на странице
         * @param {number} [pageNumber] Номер страницы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchProductList: async (q: string, pageSize?: number, pageNumber?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            if (q === null || q === undefined) {
                throw new RequiredError('q','Required parameter q was null or undefined when calling getSearchProductList.');
            }
            const localVarPath = `/api/v1/search-products`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['page_number'] = pageNumber;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Добавить гарантийный товар
         * @summary Добавить гарантийный товар
         * @param {string} productGuid Идентификатор товара
         * @param {string} purchaseAt Дата покупки
         * @param {string} purchaseShopName Место покупки
         * @param {UserAddressDTO} purchaseAddress 
         * @param {any} purchaseDocument Файл подтверждения покупки. image/doc(x)/xls(x)/pdf
         * @param {string} promocode Промокод
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(productGuid: string, purchaseAt: string, purchaseShopName: string, purchaseAddress: UserAddressDTO, purchaseDocument: any, promocode: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).createProduct(productGuid, purchaseAt, purchaseShopName, purchaseAddress, purchaseDocument, promocode, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Метод позволяет получить подробную информацию о товаре.
         * @summary Товар
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductDetail(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailDTO>> {
            const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).getProductDetail(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Метод позволяет получить список гарантийных товаров.
         * @summary Товары
         * @param {number} [pageSize] Кол-во элементов на странице
         * @param {number} [pageNumber] Номер страницы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductList(pageSize?: number, pageNumber?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductListResponseDTO>> {
            const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).getProductList(pageSize, pageNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Метод позволяет получить список гарантийных товаров.
         * @summary Поиск товаров
         * @param {string} q Артикул или ШК
         * @param {number} [pageSize] Кол-во элементов на странице
         * @param {number} [pageNumber] Номер страницы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSearchProductList(q: string, pageSize?: number, pageNumber?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchProductListResponseDTO>> {
            const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).getSearchProductList(q, pageSize, pageNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Добавить гарантийный товар
         * @summary Добавить гарантийный товар
         * @param {string} productGuid Идентификатор товара
         * @param {string} purchaseAt Дата покупки
         * @param {string} purchaseShopName Место покупки
         * @param {UserAddressDTO} purchaseAddress 
         * @param {any} purchaseDocument Файл подтверждения покупки. image/doc(x)/xls(x)/pdf
         * @param {string} promocode Промокод
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(productGuid: string, purchaseAt: string, purchaseShopName: string, purchaseAddress: UserAddressDTO, purchaseDocument: any, promocode: string, options?: any): AxiosPromise<void> {
            return ProductsApiFp(configuration).createProduct(productGuid, purchaseAt, purchaseShopName, purchaseAddress, purchaseDocument, promocode, options).then((request) => request(axios, basePath));
        },
        /**
         * Метод позволяет получить подробную информацию о товаре.
         * @summary Товар
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDetail(id: string, options?: any): AxiosPromise<ProductDetailDTO> {
            return ProductsApiFp(configuration).getProductDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Метод позволяет получить список гарантийных товаров.
         * @summary Товары
         * @param {number} [pageSize] Кол-во элементов на странице
         * @param {number} [pageNumber] Номер страницы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductList(pageSize?: number, pageNumber?: number, options?: any): AxiosPromise<ProductListResponseDTO> {
            return ProductsApiFp(configuration).getProductList(pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * Метод позволяет получить список гарантийных товаров.
         * @summary Поиск товаров
         * @param {string} q Артикул или ШК
         * @param {number} [pageSize] Кол-во элементов на странице
         * @param {number} [pageNumber] Номер страницы
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchProductList(q: string, pageSize?: number, pageNumber?: number, options?: any): AxiosPromise<SearchProductListResponseDTO> {
            return ProductsApiFp(configuration).getSearchProductList(q, pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * Добавить гарантийный товар
     * @summary Добавить гарантийный товар
     * @param {string} productGuid Идентификатор товара
     * @param {string} purchaseAt Дата покупки
     * @param {string} purchaseShopName Место покупки
     * @param {UserAddressDTO} purchaseAddress 
     * @param {any} purchaseDocument Файл подтверждения покупки. image/doc(x)/xls(x)/pdf
     * @param {string} promocode Промокод
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public createProduct(productGuid: string, purchaseAt: string, purchaseShopName: string, purchaseAddress: UserAddressDTO, purchaseDocument: any, promocode: string, options?: any) {
        return ProductsApiFp(this.configuration).createProduct(productGuid, purchaseAt, purchaseShopName, purchaseAddress, purchaseDocument, promocode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Метод позволяет получить подробную информацию о товаре.
     * @summary Товар
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductDetail(id: string, options?: any) {
        return ProductsApiFp(this.configuration).getProductDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Метод позволяет получить список гарантийных товаров.
     * @summary Товары
     * @param {number} [pageSize] Кол-во элементов на странице
     * @param {number} [pageNumber] Номер страницы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductList(pageSize?: number, pageNumber?: number, options?: any) {
        return ProductsApiFp(this.configuration).getProductList(pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Метод позволяет получить список гарантийных товаров.
     * @summary Поиск товаров
     * @param {string} q Артикул или ШК
     * @param {number} [pageSize] Кол-во элементов на странице
     * @param {number} [pageNumber] Номер страницы
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getSearchProductList(q: string, pageSize?: number, pageNumber?: number, options?: any) {
        return ProductsApiFp(this.configuration).getSearchProductList(q, pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ReclamationsApi - axios parameter creator
 * @export
 */
export const ReclamationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Создать обращение в СЦ
         * @summary Создать обращение в СЦ
         * @param {string} warrantyProductId Идентификатор гарантийного товара
         * @param {string} type Тип обращения
         * @param {string} content Суть обращения (текст обращения)
         * @param {any} documents image/doc(x)/xls(x)/pdf. max 4шт по 10мб
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReclamation: async (warrantyProductId: string, type: string, content: string, documents: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'warrantyProductId' is not null or undefined
            if (warrantyProductId === null || warrantyProductId === undefined) {
                throw new RequiredError('warrantyProductId','Required parameter warrantyProductId was null or undefined when calling createReclamation.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling createReclamation.');
            }
            // verify required parameter 'content' is not null or undefined
            if (content === null || content === undefined) {
                throw new RequiredError('content','Required parameter content was null or undefined when calling createReclamation.');
            }
            // verify required parameter 'documents' is not null or undefined
            if (documents === null || documents === undefined) {
                throw new RequiredError('documents','Required parameter documents was null or undefined when calling createReclamation.');
            }
            const localVarPath = `/api/v1/claims`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (warrantyProductId !== undefined) { 
                localVarFormParams.append('warranty_product_id', warrantyProductId as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('type', type as any);
            }
    
            if (content !== undefined) { 
                localVarFormParams.append('content', content as any);
            }
    
            if (documents !== undefined) { 
                localVarFormParams.append('documents', documents as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReclamationsApi - functional programming interface
 * @export
 */
export const ReclamationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Создать обращение в СЦ
         * @summary Создать обращение в СЦ
         * @param {string} warrantyProductId Идентификатор гарантийного товара
         * @param {string} type Тип обращения
         * @param {string} content Суть обращения (текст обращения)
         * @param {any} documents image/doc(x)/xls(x)/pdf. max 4шт по 10мб
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReclamation(warrantyProductId: string, type: string, content: string, documents: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ReclamationsApiAxiosParamCreator(configuration).createReclamation(warrantyProductId, type, content, documents, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ReclamationsApi - factory interface
 * @export
 */
export const ReclamationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Создать обращение в СЦ
         * @summary Создать обращение в СЦ
         * @param {string} warrantyProductId Идентификатор гарантийного товара
         * @param {string} type Тип обращения
         * @param {string} content Суть обращения (текст обращения)
         * @param {any} documents image/doc(x)/xls(x)/pdf. max 4шт по 10мб
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReclamation(warrantyProductId: string, type: string, content: string, documents: any, options?: any): AxiosPromise<void> {
            return ReclamationsApiFp(configuration).createReclamation(warrantyProductId, type, content, documents, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReclamationsApi - object-oriented interface
 * @export
 * @class ReclamationsApi
 * @extends {BaseAPI}
 */
export class ReclamationsApi extends BaseAPI {
    /**
     * Создать обращение в СЦ
     * @summary Создать обращение в СЦ
     * @param {string} warrantyProductId Идентификатор гарантийного товара
     * @param {string} type Тип обращения
     * @param {string} content Суть обращения (текст обращения)
     * @param {any} documents image/doc(x)/xls(x)/pdf. max 4шт по 10мб
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReclamationsApi
     */
    public createReclamation(warrantyProductId: string, type: string, content: string, documents: any, options?: any) {
        return ReclamationsApiFp(this.configuration).createReclamation(warrantyProductId, type, content, documents, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Метод получения информации о пользователе в ЛК
         * @summary Информация О Пользователе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод обновление информации о пользователе в ЛК
         * @summary Изменение Информации О Пользователе
         * @param {PatchUserRequest} patchUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserInfo: async (patchUserRequest: PatchUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchUserRequest' is not null or undefined
            if (patchUserRequest === null || patchUserRequest === undefined) {
                throw new RequiredError('patchUserRequest','Required parameter patchUserRequest was null or undefined when calling patchUserInfo.');
            }
            const localVarPath = `/api/v1/user`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof patchUserRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(patchUserRequest !== undefined ? patchUserRequest : {}) : (patchUserRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Метод получения информации о пользователе в ЛК
         * @summary Информация О Пользователе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponseDTO>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getUserInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Метод обновление информации о пользователе в ЛК
         * @summary Изменение Информации О Пользователе
         * @param {PatchUserRequest} patchUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUserInfo(patchUserRequest: PatchUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).patchUserInfo(patchUserRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Метод получения информации о пользователе в ЛК
         * @summary Информация О Пользователе
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(options?: any): AxiosPromise<UserResponseDTO> {
            return UserApiFp(configuration).getUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * Метод обновление информации о пользователе в ЛК
         * @summary Изменение Информации О Пользователе
         * @param {PatchUserRequest} patchUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUserInfo(patchUserRequest: PatchUserRequest, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).patchUserInfo(patchUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Метод получения информации о пользователе в ЛК
     * @summary Информация О Пользователе
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserInfo(options?: any) {
        return UserApiFp(this.configuration).getUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Метод обновление информации о пользователе в ЛК
     * @summary Изменение Информации О Пользователе
     * @param {PatchUserRequest} patchUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public patchUserInfo(patchUserRequest: PatchUserRequest, options?: any) {
        return UserApiFp(this.configuration).patchUserInfo(patchUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

}


