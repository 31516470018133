import React, {useEffect, useState} from "react";
import Logo from '../../../../icons/icon-logo.svg';
import LogoDamixa from '../../../../icons/icon-header-damixa.svg';
import LogoDorff from '../../../../icons/icon-header-dorff.svg';
import * as S from './styled';
import {hasData} from "../../../../../libs/remote";
import {useAppSelector} from "@app/store";
import {selectUser} from "@app/store/core/selectors";

const LogoSlider = () => {
    const user = useAppSelector(selectUser);
    const [path, setPath] = useState("/");

    useEffect(() => {
        if (hasData(user) && user.data?.is_user_verified) {
            setPath("/myGoods");
        } else {
            setPath("/")
        }
    }, [user]);

    const settings = {
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
    };

    return (
        <div>
            <S.SliderWrap {...settings}>
                <S.HeaderLogo href={path}>
                    <Logo />
                </S.HeaderLogo>
                <S.HeaderLogo href={path}>
                    <LogoDamixa />
                </S.HeaderLogo>
                <S.HeaderLogo href={path}>
                    <LogoDorff />
                </S.HeaderLogo>
            </S.SliderWrap>
        </div>
    );
}

export default LogoSlider;

