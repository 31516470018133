import React from "react";
import LogoAmpmMini from "../../../icons/footer-ampm.svg";
import LogoDamixaMini from "../../../icons/footer-damixa.svg";
import LogoDorffMini from "../../../icons/footer-dorff.svg";
import ArrowUp from "../../../icons/arrow-up.svg";
import * as S from "./styled/index";
import {changePolicyModalState} from "@app/store/core/slice";
import {useAppDispatch} from "@app/store";
import {useTranslation} from "next-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleToTopButtonClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    evt.preventDefault();
    window.scrollTo(0, 0);
  };

  const policyHandler = () => {
    dispatch(changePolicyModalState({ visibility: true }));
    window.scrollTo(0, 0);
  }

  return (
    <S.FooterContainer>
      <S.FooterWrapper>
        <S.FooterRow>
          <S.TopRowContainer>
            <S.BrandsWrap>
              <LogoAmpmMini />
              <LogoDamixaMini />
              <LogoDorffMini />
            </S.BrandsWrap>
            <S.ButtonToTop isMobile onClick={handleToTopButtonClick}>
              <ArrowUp />
            </S.ButtonToTop>
          </S.TopRowContainer>
          <S.NavWrapper>
            <S.FooterNav>
              <S.FooterLink href="/contacts">{t("common:navigation.contacts")}</S.FooterLink>
              <S.FooterLinkLk href="/profile">{t("common:navigation.lk")}</S.FooterLinkLk>
              <S.ButtonToTop isDesktop onClick={handleToTopButtonClick}>
                <ArrowUp />
              </S.ButtonToTop>
            </S.FooterNav>
          </S.NavWrapper>
        </S.FooterRow>
        <S.FooterRow>
          <S.CopyrightRow>
            <S.Copyright>
              <p>
                {t("common:footer.allRightsReserved")}
                <S.PolicyTrigger type="button" onClick={policyHandler}>{t("common:footer.privacyPolicy")}</S.PolicyTrigger>
              </p>
              <p>
                {t("common:footer.description")}
              </p>
            </S.Copyright>
            <S.ButtonToTop isTablet onClick={handleToTopButtonClick}>
              <ArrowUp />
            </S.ButtonToTop>
          </S.CopyrightRow>
        </S.FooterRow>
      </S.FooterWrapper>
    </S.FooterContainer>
  );
};

export default Footer;
