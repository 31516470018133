import { UserController } from "@app/api";
import {put, takeLatest} from "@redux-saga/core/effects";
import { all } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {postUserData, patchUserDataStatus} from "@app/store/userProfile/slice";
import {resetConfirmationStatus} from "@app/store/core/slice";
import {failure, pending, RemoteDataStatus, success} from "../../../libs/remote";

function* userProfileSaga(): SagaIterator {
    yield all([
        /**
         * Отправление изменений в данных пользователя
         */
        takeLatest(postUserData, function* postUserDataSaga({payload}) {
            try {
                yield put(patchUserDataStatus(pending()));
                yield UserController.patchUserInfo(payload);
                yield put(patchUserDataStatus(success("")));
            }
            catch (err) {
                yield put(patchUserDataStatus(failure(err.message)));
            }
        }),
    ]);
}

export default userProfileSaga;