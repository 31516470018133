import {theme, ThemeProvider, GlobalStyle} from "../styled-components";
import { useAppSelector, wrapper} from "../store";
import Layout from "@app/containers/layout";
import { appWithTranslation } from "next-i18next";
import { ConfigProvider } from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import "dayjs/locale/ru";
import 'swiper/css';
import "../global.css";
import {useRouter} from "next/router";
import {selectUser} from "@app/store/core/selectors";
import { RemoteDataStatus} from "../../libs/remote";
import {parseCookies} from "nookies";
import {fetchUser, } from "@app/store/core/slice";

const WrappedApp = ({Component, pageProps}) => {
    const router = useRouter();
    const user = useAppSelector(selectUser);
    const reqSvg = require.context("src/icons", false, /\.svg$/);
    reqSvg.keys().reduce((images: { [key in string]: any }, path) => {
        images[path] = reqSvg(path);
        return images;
    }, {});

    // useEffect(() => {
    //     if (pageProps.token) {
    //         if (!hasData(user) || hasData(user) && !user.data.is_user_verified) {
    //             if (router.pathname !== '/registration') {
    //                 router.push('/registration')
    //             }
    //         } else {
    //             if (hasData(user) && user.data.is_user_verified && (router.pathname === "/registration" || router.pathname === "/")) {
    //                router.push('/myGoods')
    //             }
    //         }
    //     } else {
    //         if (router.pathname !== '/') {
    //             router.push('/')
    //         }
    //     }
    // }, [pageProps.token]);

    return (
        <ThemeProvider theme={theme}>
            <ConfigProvider locale={ruRU}>
                <GlobalStyle />
                <Layout>
                    <Component {...pageProps}/>
                </Layout>
            </ConfigProvider>
        </ThemeProvider>
    )
};
export let TOKEN;

WrappedApp.getInitialProps = wrapper.getInitialAppProps(
    store => async ({ctx, Component}) => {
    // 1. Добавляем токен в configuration для запросов
    if (typeof window === 'undefined') {
        TOKEN = await parseCookies(ctx)?.access_token;
    }

    // 2. Запрос user
    await store.dispatch(fetchUser());

    // 3. Ожидаем окончания fetchUser
    await new Promise((resolve) => {
        const unsubscribe = store.subscribe(() => {
            const state = store.getState();
            if (state.core.user.status !== RemoteDataStatus.Pending) {
                unsubscribe();
                resolve(null);
            }
        });
    });

    return {
        pageProps: Component.getInitialProps ? await Component.getInitialProps({...ctx, store}) : {}
    }
});

export default wrapper.withRedux(appWithTranslation(WrappedApp));

