import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
`;

export const ContentContainer = styled.main`
  flex: 1 0 auto;
  position: relative;
`;

export const FooterWrapper = styled.div`
  flex: 0 0 auto;
`;
