import {AxiosError, AxiosResponse} from "axios";
import {AuthController, basePath, ProductsController, UserController} from "@app/api";
import {deleteCookie, getCookie} from "@app/utils/core-functions";
import {put, takeLatest} from "@redux-saga/core/effects";
import {all} from "redux-saga/effects";
import {SagaIterator} from "redux-saga";
import {failure, initialized, pending, RemoteDataStatus, success} from "../../../libs/remote";
import {
    confirmUser,
    fetchUser,
    postRegistrationForm,
    refreshAuth,
    resetConfirmation,
    resetConfirmationStatus,
    setConfirmStatus,
    setPostRegistrationFormStatus,
    setUser,
    signIn,
    signOut,
    signStatus
} from "@app/store/core/slice";
import {
    ConfirmationLoginResponseDTO,
    ResetConfirmationCodeResponseDTO,
    TokenResponseDTO,
    UserResponseDTO
} from "@app/api/generated-api";
import {TOKEN} from "@app/pages/_app";
import {PostProductErrorEnum} from "@app/store/myGoods/types";
import {setPostProductStatus} from "@app/store/myGoods/slice";

function* coreSaga(): SagaIterator {
    yield all([
        /**
         * Авторизировать пользователя
         */
        takeLatest(signIn, function* signInSaga({ payload }) {
            try {
                yield put(signStatus(pending()));
                const { data }: { data: ConfirmationLoginResponseDTO } = yield AuthController.loginByPhone(payload);
                yield put(signStatus(success(data)));
            } catch (err) {
                if ((err as AxiosError).response.status === 403) {
                    yield put(signStatus(failure((err as AxiosError).response.data?.jailTime?.toString())));
                } else {
                    yield put(signStatus(failure((err as AxiosError)?.response?.data.message)));
                }
            }
        }),

        /**
         * Отправить повторно код авторизации
         */
        takeLatest(resetConfirmation, function* resetConfirmationSaga({ payload }) {
            try {
                yield put(resetConfirmationStatus(initialized()));
                const {data}: {data: ResetConfirmationCodeResponseDTO} = yield AuthController.resendConfirmationCode(payload);
                yield put(resetConfirmationStatus(success(data)));
            } catch (err) {
                yield put(resetConfirmationStatus(failure((err as AxiosError)?.response?.data.message)));
            }
        }),

        /**
         * Получение информации о пользователе
         */
        takeLatest(fetchUser, function* setUserSaga() {
            try {
                yield put(setUser(pending()));
                const { data }: { data: UserResponseDTO } = yield UserController.getUserInfo();
                yield put(setUser(success(data)));
            } catch (err) {
                yield put(setUser(failure((err as AxiosError).message)));
            }
        }),

        /**
         * Разлогинить пользователя
         */
        takeLatest(signOut, function* signOutSaga() {
            try {
                yield AuthController.makeLogout();
                deleteCookie("access_token");
                deleteCookie("refresh");
                yield window.location.reload();
                yield window.location.replace("/");
            } catch (err) {
                console.error(err);
            }
        }),

        /**
         * Подтверждение номера телефона
         */
        takeLatest(confirmUser, function* confirmUserSaga({payload}) {
            try {
                yield put(setConfirmStatus(RemoteDataStatus.Pending));
                const { data }: { data: TokenResponseDTO } = yield AuthController.confirmationLoginByPhone(payload);

                const { access_token, expired_in } = data;

                if (expired_in) {
                    document.cookie = `access_token=${access_token}; max-age=${expired_in}`;
                    document.cookie = `refresh=true; max-age=${expired_in - 3600}`;
                }
                yield put(setConfirmStatus(RemoteDataStatus.Success));
            } catch (err) {
                yield put(setConfirmStatus(RemoteDataStatus.Failure));
            }
        }),

        /**
         * Обновление access_token
         */
        takeLatest(refreshAuth, function* checkAuthSaga() {
            try {
                const res: AxiosResponse<TokenResponseDTO> = yield AuthController.makeRefresh();
                if (res.status !== 200) throw new Error(`${res.status}`);
                const { access_token, expired_in } = res.data;
                deleteCookie("access_token");
                deleteCookie("refresh");
                if (expired_in) {
                    document.cookie = `access_token=${access_token}; max-age=${expired_in}`;
                    document.cookie = `refresh=true; max-age=${expired_in - 3600}`;
                }
            } catch (err) {
                yield put(signOut());
                console.error(err);
            }
        }),

        /**
         * Разлогинить пользователя
         */
        takeLatest(postRegistrationForm, function* postRegistrationFormSaga({payload}) {
            try {
                const userToken = getCookie("access_token");
                yield put(setPostRegistrationFormStatus(pending()));
                let data: any;
                let fileError = null;
                yield fetch(`${basePath}/api/v1/products`, {
                    method: "post",
                    body: payload.data.productData,
                    headers: {
                        Authorization: `Bearer ${userToken}` || "",
                    },
                })
                    .then(async (res) => {
                        if (res.ok) {
                            data = res
                        } else {
                            const message = await res.json().then(res => JSON.stringify(res))
                            throw new Error(message);
                        }
                    })
                    .catch((err: Error) => {
                        if (!!JSON.parse(err.message)["purchase_document"]) {
                            fileError = PostProductErrorEnum.VALIDATE_FILE
                        }
                        console.log(JSON.parse(err.message))

                        if (JSON.parse(err.message).message === "promocode_expired") {
                            fileError = PostProductErrorEnum.PROMO_EXPIRED
                        }

                        if (JSON.parse(err.message).message === "promocode_already_used") {
                            fileError = PostProductErrorEnum.PROMO_USED
                        }

                        if (JSON.parse(err.message).message === "promocode_not_founded") {
                            fileError = PostProductErrorEnum.PROMO_NOT_FOUND
                        }
                    });

                if (!fileError) {
                    yield UserController.patchUserInfo(payload.data.userData);
                }

                if (!!data) {
                    yield put(setPostRegistrationFormStatus(success("")));
                } else {
                    if (!!fileError) {
                        yield put(setPostRegistrationFormStatus(failure(fileError)));
                    } else {
                        yield put(setPostRegistrationFormStatus(failure(fileError ?? "")));
                    }
                }
            } catch (err) {
                yield put(setPostRegistrationFormStatus(failure(err.message)));
            }
        }),
    ]);
}

export default coreSaga;