import * as styledComponents from "styled-components";
import { ThemeType } from "./theme";
import { DefaultTheme } from "styled-components";

const style = {
    default: styledComponents.default,
    css: styledComponents.css,
    ThemeProvider: styledComponents.ThemeProvider,
    ThemeContext: styledComponents.ThemeContext,
    createGlobalStyle: styledComponents.createGlobalStyle,
} as styledComponents.ThemedStyledComponentsModule<DefaultTheme>;

export const { css, createGlobalStyle, ThemeProvider, ThemeContext } = style;

export * from "./theme";

export const GlobalStyle = createGlobalStyle<styledComponents.ThemeProps<ThemeType>>`
  ${({ theme }) => css`
    #__next {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    };
    
    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    html {
      box-sizing: border-box;
      scroll-behavior: smooth;
    }

    body {
      font-family: "Tondo Corp", "Open Sans", Helvetica, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      font-variant: tabular-nums;
      line-height: 1;
      color: ${theme.colors.greyDarkTwo};
      margin: 0;
      min-width: 320px;
      text-rendering: optimizeSpeed;
      text-decoration-skip-ink: none;
      -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: transparent;
      background: linear-gradient(to left, #f2f2f2 50%, #ffffff 50%);
      overflow: auto;

      &:after {
        position: fixed;
        height: 100%;
        background-color: #fff;
        content: '';
        display: block;
        bottom: 0;
        width: 100%;
        z-index: -1;
      }
    }

    :focus {
      outline: none;
    }

    ul,
    ol {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    dd {
      margin: 0;
    }

    a {
      background-color: transparent;
      text-decoration: none;
    }

    p {
      margin: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      font-size: inherit;
    }

    img {
      display: block;
      border: none;
      width: 100%;
      height: auto;
    }

    img[src=''],
    img:not([src]) {
      visibility: hidden;
    }

    .ant-tooltip-placement-top .ant-tooltip-arrow {
      display: none;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
      padding: 0;
      margin: 0;
      border: none;
      border-radius: 0;
      box-shadow: none;
      background-color: transparent;
      font: inherit;
      color: inherit;
      letter-spacing: inherit;
    }

    button,
    input {
      overflow: visible;
    }

    button,
    select {
      text-transform: none;
    }

    #uw-main-button,
    #uw-main-button-close {
      display: none !important;
    }

    #usedesk-messenger {
      z-index: 10000 !important;
    }

    .grecaptcha-badge {
      display: none !important;
    }

    img {
      color: transparent;
    }

    a {
      color: ${theme.colors.black}
    }
  `}
`;

export default style.default;