import {
    Configuration,
    AuthApi,
    UserApi, ProductsApi,
} from "./generated-api";
import { getCookie } from "../utils/core-functions";
import {DEV_API_PATH, ENV_TYPE, PROD_API_PATH} from "@app/constants";
import {TOKEN} from "@app/pages/_app";

export const basePath = process.env.NEXT_PUBLIC_RENDER_ENVIRONMENT === ENV_TYPE.prod ? PROD_API_PATH : DEV_API_PATH;

const configuration = new Configuration({
    basePath,
    accessToken: () => typeof window === "undefined" ? TOKEN || "" : getCookie("access_token") || "",
});

export const AuthController = new AuthApi(configuration);
export const UserController = new UserApi(configuration);
export const ProductsController = new ProductsApi(configuration);

export type {} from "./generated-api";
