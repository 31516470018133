import styled, { css } from "styled-components";
import Link from "next/link";

export const FooterContainer = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    width: 100%;
    background-color: ${colors.black};
    height: 210px;
    padding: 48px 15px 40px;
    overflow: hidden;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      padding: 40px 40px 100px;
      height: 300px;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      padding: 32px 24px 100px;
      position: relative;
      height: max-content;
    }
  `}
`;

export const FooterWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    max-width: 1360px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      max-width: none;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      gap: 24px;
    }
  `}
`;

export const FooterRow = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    justify-content: space-between;
    margin: 0;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      justify-content: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 40px;
      margin-bottom: 0;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      gap: 24px;
    }
  `}
`;

export const BrandsWrap = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    gap: 24px;
    align-items: center;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      order: 1;
    }
  `}
`;

export const LogoWrapper = styled(Link)`
  ${({ theme: { colors, breakpoints } }) => css`
    display: flex;
    width: 320px;
    align-items: center;
    justify-content: flex-start;
    column-gap: 24px;

    svg {
      position: relative;

      &:after {
        content: "";
        background-color: ${colors.white};
        height: 100%;
        width: 1px;
      }
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      column-gap: 16px;
    }
  `}
`;

export const LogoText = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    color: ${colors.white};
    font-size: 12px;
    line-height: 1.3;
    letter-spacing: 0.25px;
    width: 159px;
    margin: 0;
    padding-left: 24px;
    border-left: 1px solid ${colors.white};
    position: relative;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      padding-left: 16px;
    }

    @media (max-width: ${breakpoints.mobileWidth}) {
      margin: 0;
    }
  `}
`;

export const NavWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    align-items: center;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      width: 100%;
    }
  `}
`;

export const FooterNav = styled.nav`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 48px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  `}
`;

export const FooterLink = styled(Link)`
  ${({ theme: { colors, breakpoints } }) => css`
    color: ${colors.white};
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    background-image: none;

    svg {
      color: ${colors.white};
      width: 24px;
      height: 24px;
      display: none;
    }

    &:focus {
      color: ${colors.white};
    }

    &:hover {
      color: ${colors.blueGray};
    }

    @media (max-width: ${breakpoints.maxTableWidth}) {
      &:focus,
      &:hover {
        color: ${colors.white};
      }
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      position: relative;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      width: 100%;
      height: 44px;
      margin-right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-of-type {
        border-bottom: none;
      }

      svg {
        display: initial;
      }
    }
  `}
`;

export const FooterLinkLk = styled(Link)`
  ${({ theme: { colors, breakpoints } }) => css`
    color: ${colors.white};
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    background-image: none;

    svg {
      color: ${colors.white};
      width: 24px;
      height: 24px;
      display: none;
    }

    &:focus {
      color: ${colors.white};
    }
    &:hover {
      color: ${colors.blueGray};
    }

    @media (max-width: ${breakpoints.maxTableWidth}) {
      &:focus,
      &:hover {
        color: ${colors.white};
      }
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      display: none;
    }
  `}
`;

export const CopyrightRow = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    justify-content: space-between;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      order: 2;
    }
  `}
`;

export const Copyright = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    opacity: 0.5;
    width: 600px;
    display: flex;
    flex-direction: column;

    p {
      margin: 0;
      font-size: 11px;
      line-height: 1.3;
      color: ${colors.white};
    }

    a {
      color: ${colors.white};
      text-decoration: underline;
    }

    @media (max-width: ${breakpoints.maxTableWidth}) {
      margin-right: auto;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      width: 100%;
      a {
        display: inline-block;
        vertical-align: top;
        margin: 8px 0;
        width: 100%;
      }
    }
  `}
`;

export const ButtonToTop = styled.div<{ isDesktop?: boolean; isTablet?: boolean; isMobile?: boolean }>`
  ${({ theme: { colors, breakpoints }, isDesktop, isTablet, isMobile }) => css`
    width: 40px;
    height: 40px;
    border: 1px solid ${colors.white};
    cursor: pointer;
    //display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: transparent;
    display: ${isDesktop ? "flex" : "none"};

    svg {
      width: 8px;
      height: 12px;
      color: ${colors.white};
    }

    svg path {
      stroke: ${colors.white};
    }

    @media (max-width: ${breakpoints.maxTableWidth}) {
      display: ${isTablet ? "flex" : "none"};
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      position: absolute;
      top: 24px;
      right: 20px;
      display: ${isMobile ? "flex" : "none"};
    }
  `}
`;

export const TopRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PolicyTrigger = styled.button`
  cursor: pointer;
  text-decoration: underline;
`;
