import styled, { css } from "styled-components";
import Link from "next/link";

export const HeaderContainer = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    height: 80px;
    display: flex;
    align-items: center;
    background-color: ${colors.white};
    position: relative;
    overflow: hidden;

    /*Это необходимо, чтобы при скрытии полоски хедер не скакал на 20 пикселей вправо*/

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      overflow: hidden;
      height: 1px;
      background-color: ${colors.lineGray};
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      height: 48px;
    }
  `}
`;

export const HeaderWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1390px;
    margin: 0 auto;
    width: 100%;
    padding: 0 15px;
    max-height: 100%;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      max-width: 100%;
      justify-content: space-between;
      padding: 0 40px;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      padding: 0 20px;
    }
  `}
`;

export const HeaderNav = styled.nav`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 48px;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      display: none;
    }
  `}
`;

export const HeaderLinkProfile = styled(Link)`
  ${({ theme: { colors, breakpoints } }) => css`
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: ${colors.black};

    @media (max-width: ${breakpoints.maxTableWidth}) {
      display: none;
    }
  `}
`;

export const HeaderLink = styled(Link)`
  ${({ theme: { colors } }) => css`
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: ${colors.black};

    :hover {
      color: ${colors.black};
    }
  `}
`;

export const HeaderIcons = styled.div`
  display: flex;
  gap: 40px;
  width: 130px;
  justify-content: flex-end;
  align-items: center;
`;
