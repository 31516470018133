import IconMyGoods from "../../../icons/icon-myGoods.svg";
import IconLogin from "../../../images/icon-user.png";
import IconPhone from "../../../images/icon-telephone.png";
import * as S from "./styled";
import { useAppSelector } from "@app/store";
import { selectUser } from "@app/store/core/selectors";
import { hasData } from "../../../../libs/remote";
import React from "react";
import Image from "next/image";

const FooterToolbar = () => {
  const user = useAppSelector(selectUser);

  return (
    <S.FooterToolbarWrap>
      <S.NavWrap>
        <S.NavLink href="/myGoods">
            <S.IconWrapper>
                <IconMyGoods />
            </S.IconWrapper>
            Мои товары
        </S.NavLink>
        {/*<S.NavLink href="/sales">*/}
        {/*    <IconStock />*/}
        {/*    Акции*/}
        {/*</S.NavLink>*/}
        {hasData(user) && user.data?.is_user_verified && (
          <S.NavLink href="/profile">
              <S.IconWrapper>
                  <Image src={IconLogin} alt={'profile'} width={20} height={20}/>
              </S.IconWrapper>
            Профиль
          </S.NavLink>
        )}
        {hasData(user) && user.data?.is_user_verified && (
            <S.NavLink href="/contacts">
                <S.IconWrapper>
                    <Image src={IconPhone} alt={'contacts'} width={20} height={20}/>
                </S.IconWrapper>
              Контакты
            </S.NavLink>
        )}
      </S.NavWrap>
    </S.FooterToolbarWrap>
  );
};

export default FooterToolbar;
