
export type PagerType = {
    page_size: number | null,
    page_number: number | null,
    showMore: boolean
};

export enum PostProductErrorEnum {
    VALIDATE_FILE = "VALIDATE_FILE",
    PROMO_NOT_FOUND = "PROMO_NOT_FOUND",
    PROMO_USED = "PROMO_USED",
    PROMO_EXPIRED = "PROMO_EXPIRED"
}

