import styled, {css} from "styled-components";
import Link from "next/link";

export const FooterToolbarWrap = styled.div`
${({ theme: { colors, breakpoints } }) => css`
  display: none;
  bottom: 0;
  left: 0;
  height: 64px;
  background-color: ${colors.white};
  width: 100%;
  border-top: 1px solid ${colors.lineGray};
  position: fixed;
  z-index: 10;
  
  @media (max-width:${breakpoints.maxTableWidth}) {
    display: block;
  }
`}
`

export const NavWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  max-width: 414px;
  margin: 0 auto;
  justify-content: space-evenly;
`

export const NavLink = styled(Link)`
  ${({ theme: { colors } }) => css`
  background-image: none;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  color: ${colors.darkGray};
  padding: 8px 0 22px;
  white-space: nowrap;
  background-color: transparent;
  border: none;

  svg {
    width: 20px !important;
    height: 20px !important;
  }

    :hover {
      color: ${colors.darkGray};
    }
`}
`;

export const IconWrapper = styled.div`
  width: 20px !important;
  height: 20px !important;
  min-height: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important;
  max-height: 20px !important;
  margin-bottom: 5px;
`;
