import styled from "styled-components";
import Slider from "react-slick";
import Link from "next/link";

export const SliderWrap = styled(Slider)`
    width: 130px;
  
  .slick-track {
    display: flex;
  }
`

export const HeaderLogo = styled(Link)`
  display: flex;
`;

