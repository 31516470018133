const indents = {
    i2: '2px',
    i4: '4px',
    i6: '6px',
    i8: '8px',
    i10: '10px',
    i12: '12px',
    i15: '15px',
    i16: '16px',
    i18: '18px',
    i20: '20px',
    i24: '24px',
    i28: '28px',
    i32: '32px',
    i40: '40px',
    i48: '48px',
    i64: '64px',
    i80: '80px',
} as const;

export default indents;