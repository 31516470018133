import React from "react";
import { useTranslation } from "next-i18next";
import IconProfile from "../../../icons/icon-login.svg";
import LogoSlider from "@app/containers/header/components/logo-slider";
import * as S from "./styled";
import { useAppSelector } from "@app/store";
import { selectUser } from "@app/store/core/selectors";
import { hasData } from "../../../../libs/remote";

const Header: React.FC = () => {
  const { t } = useTranslation();
  const user = useAppSelector(selectUser);

  const renderHeader = () => {
    return (
      <S.HeaderContainer>
        <S.HeaderWrapper>
          <LogoSlider />
          <S.HeaderNav>
            {hasData(user) && user.data?.is_user_verified && (
                <S.HeaderLink href="/myGoods">{t("common:navigation.myGoods")}</S.HeaderLink>
            )}
            <S.HeaderLink href="/contacts">{t("common:navigation.contacts")}</S.HeaderLink>
          </S.HeaderNav>
          <S.HeaderIcons>
            {hasData(user) && user.data?.is_user_verified && (
              <S.HeaderLinkProfile href="/profile">
                <IconProfile />
              </S.HeaderLinkProfile>
            )}
          </S.HeaderIcons>
        </S.HeaderWrapper>
      </S.HeaderContainer>
    );
  };

  return <>{renderHeader()}</>;
};

export default Header;
