import { combineReducers } from "@reduxjs/toolkit";
import coreSlice from "@app/store/core/slice";
import myGoodsSlice from "@app/store/myGoods/slice";
import userProfileSlice from "@app/store/userProfile/slice";

const rootReducer = combineReducers({
    core: coreSlice,
    products: myGoodsSlice,
    userProfile: userProfileSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
