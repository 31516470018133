import React, {useEffect} from "react";
import Header from "../header/page/index";
import { Footer } from "../footer";
import FooterToolbar from "@app/containers/footer/footer-toolbar";
import * as S from "./styled";
import Head from "next/head";
import { useAppDispatch, useAppSelector } from "@app/store";
import { selectPolicyVisible } from "@app/store/core/selectors";
import PolicyModal from "@app/components/entities/policy-modal";

const Layout = ({ children }) => {
  const policyStatus = useAppSelector(selectPolicyVisible);

  return (
    <>
      <Head>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon.png" />
      </Head>
      <S.MainContainer>
        <Header />
        <S.ContentContainer>{children}</S.ContentContainer>
        <S.FooterWrapper>
          <Footer />
          <FooterToolbar />
        </S.FooterWrapper>
      </S.MainContainer>
      {policyStatus && <PolicyModal />}
    </>
  );
};

export default Layout;
